import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Link } from '../Link'
import { Txt } from '../Txt'

export const Guide: React.FC = () => {
    const muiTheme = useTheme()
    return (
        <Grid container spacing={0}>
            <Grid item sm={4} xs={12}>
                <Wrapper theme={muiTheme}>
                    <Title theme={muiTheme}>
                        <Txt>低価格・高品質の理由</Txt>
                    </Title>
                    <StaticImage src="../../images/common/cost.jpg" alt="" />
                    <Description theme={muiTheme}>
                        miss
                        platinumはワークショップから直接お客様に納品するメーカー直販の通販サイトのため、店舗費用、広告費用、中間マージンを全てカットした原価に近い低価格の結婚指輪、婚約指輪をご利用頂けます。
                    </Description>
                    <LinkWrapper>
                        <Link
                            to="/low-priced/"
                            aria-label="安心価格の理由について詳しく見る"
                        >
                            <Txt size="s">詳しく見る</Txt>
                        </Link>
                    </LinkWrapper>
                </Wrapper>
            </Grid>
            <Grid item sm={4} xs={12}>
                <Wrapper theme={muiTheme}>
                    <Title theme={muiTheme}>
                        <Txt>鑑定書付きの品質保証</Txt>
                    </Title>
                    <StaticImage src="../../images/common/quality.jpg" alt="" />
                    <Description theme={muiTheme}>
                        ブライダルジュエリーでは定番のPT950ハードプラチナは価値と強度を両立した一生の記念品に相応しい材質。ダイヤモンドも国内の鑑別機関を通し、カラット、カラー、クラリティ、カットの全て記載した鑑定書、製品の品質保証書付き。
                    </Description>
                    <LinkWrapper>
                        <Link
                            to="/quality/"
                            aira-label="高品質へのこだわりについて詳しく見る"
                        >
                            <Txt size="s">詳しく見る</Txt>
                        </Link>
                    </LinkWrapper>
                </Wrapper>
            </Grid>
            <Grid item sm={4} xs={12}>
                <Wrapper theme={muiTheme}>
                    <Title theme={muiTheme}>
                        <Txt>充実のアフターサービス</Txt>
                    </Title>
                    <StaticImage
                        src="../../images/common/afterservice.jpg"
                        alt=""
                    />
                    <Description theme={muiTheme}>
                        ご購入後の商品は5,400円でサイズ直し、新品仕上げなど、メッキ加工や石留め加工といったリペアーも格安で承ります。
                    </Description>
                    <LinkWrapper>
                        <Link
                            to="/afterservice/"
                            aria-label="アフターサービスについて詳しく見る"
                        >
                            <Txt size="s">詳しく見る</Txt>
                        </Link>
                    </LinkWrapper>
                </Wrapper>
            </Grid>
        </Grid>
    )
}

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    [theme.breakpoints.up('sm')]: {
        padding: '8px',
    },
}))
const Title = styled('h3')(({ theme }) => ({
    margin: '0 0 4px',
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
        textAlign: 'center',
    },
}))
const Description = styled('p')(({ theme }) => ({
    margin: 0,
    padding: '8px',
    fontSize: '13px',
    [theme.breakpoints.up('sm')]: {
        marginTop: '8px',
        padding: 0,
    },
}))
const LinkWrapper = styled('div')({
    padding: '8px',
    textAlign: 'right',
})
